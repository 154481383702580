<template>
  <div id="shopdetails">
    <Header :newCart="newCart"></Header>
    <div class="content-wrapper">
      <div class="breadcrumb-wrap bg-f br-1">
        <div class="overlay bg-black op-9"></div>
        <img :src="shape1" alt="Image" class="br-shape-1" />
        <img :src="shape2" alt="Image" class="br-shape-2" />
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-title">
                <h2>Shop Details</h2>
                <ul class="breadcrumb-menu list-style">
                  <li><router-link to="/">Home</router-link></li>
                  <li><router-link to="/shop">Shop</router-link></li>
                  <li>Shop Details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="product-details-wrap pt-100 pb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="swiper-container mySwiper2 single-product-slider">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <img :src="baseUrl() + item.image" />
                  </div>
                  <!-- <div class="swiper-slide">
                    <img :src="singleproduct2" />
                  </div>
                  <div class="swiper-slide">
                    <img :src="singleproduct3" />
                  </div>
                  <div class="swiper-slide">
                    <img :src="singleproduct4" />
                  </div> -->
                </div>
              </div>
              <!-- <div
                thumbsSlider=""
                class="swiper-container mySwiper single-product-thumbs"
              >
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <img :src="singleproduct1" />
                  </div>
                  <div class="swiper-slide">
                    <img :src="singleproduct2" />
                  </div>
                  <div class="swiper-slide">
                    <img :src="singleproduct3" />
                  </div>
                  <div class="swiper-slide">
                    <img :src="singleproduct4" />
                  </div>
                </div>
                <div class="product-slider-next slider-btn style2">
                  <i class="flaticon-right-arrow-angle"></i>
                </div>
                <div class="product-slider-prev slider-btn style2">
                  <i class="flaticon-left-arrow-1"></i>
                </div>
              </div> -->
            </div>
            <div class="col-lg-6">
              <div class="single-product-details">
                <div class="single-product-title">
                  <h2>{{ item.name }}</h2>
                  <!-- <div class="product-ratings">
                    <ul class="ratings list-style">
                      <li><i class="flaticon-star-1"></i></li>
                      <li><i class="flaticon-star-1"></i></li>
                      <li><i class="flaticon-star-1"></i></li>
                      <li><i class="flaticon-star-1"></i></li>
                      <li><i class="flaticon-star-1"></i></li>
                    </ul>
                    <span>(45)</span>
                  </div> -->
                  <h3>
                    <span
                      >${{
                        addOnTotal > 0 ? addOnTotal.toFixed(2) : item.price
                      }}</span
                    >
                    <!--<span class="discount">$45</span>-->
                  </h3>
                </div>
                <div class="single-product-desc">
                  <span v-html="item.desc"></span>
                </div>
                <div class="product-more-option">
                  <!-- <div class="product-more-option-item">
                    <h6>Category :</h6>
                    <a href="#">Fastfood</a>
                  </div>
                  <div class="product-more-option-item">
                    <h6>Quantity:</h6>
                    <div class="product-quantity style1">
                      <div class="qtySelector">
                        <span class="decreaseQty"
                          ><i class="flaticon-left-arrow-1"></i
                        ></span>
                        <input type="text" class="qtyValue" value="1" />
                        <span class="increaseQty"
                          ><i class="flaticon-next"></i
                        ></span>
                      </div>
                    </div>
                  </div> -->

                  <!--compozall-->
                  <div class="mt-4 mb-4" v-if="options && options.length > 0">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Options
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div class="row">
                              <div
                                class="col-md-6"
                                v-for="(option, i) in options"
                                :key="i"
                              >
                                <div class="form-group text-left">
                                  <label for="sel1">{{ option.name }}</label>
                                  <select
                                    class="form-control"
                                    id="sel1"
                                    @change="selectAddon($event, option)"
                                  >
                                    <option selected disabled>
                                      --Select option--
                                    </option>
                                    <option
                                      v-for="(optionItem, i) in option.addons"
                                      :key="i"
                                      :value="JSON.stringify(optionItem)"
                                    >
                                      {{
                                        `${optionItem.name}($${optionItem.price})`
                                      }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Additions
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div
                              class="row text-left ml-2"
                              v-for="(addon, j) in addons"
                              :key="j"
                            >
                              <div class="col-md-12">{{ addon.name }}</div>
                              <div
                                class="col-md-6"
                                v-for="(addonItem, j) in addon.addons"
                                :key="j"
                              >
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :value="JSON.stringify(addonItem)"
                                    @change="selectAddon($event, addon)"
                                  />
                                  {{ addonItem.name }} (${{ addonItem.price }})
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--endend-->

                    <div class="product-more-option-item">
                      <h6>Availability :</h6>
                      <a href="#">In Stock</a>
                    </div>
                  </div>
                  <div class="single-product-option">
                    <button
                      type="button"
                      @click="addtocart(item)"
                      :class="showButton == true ? 'btn style2' : 'btn style2 disabled'"
                      else
                    >
                      <span><i class="flaticon-bag"></i>Add To Cart</span>
                    </button>
                    <!-- <router-link to="/#" class="btn style1" @click="addtocart(item)"
                    ><i class="flaticon-bag"></i>Add To Cart</router-link
                  > -->
                    <!--<button type="button" class="btn style2">
                    <span>Buy Now </span>
                  </button>-->
                  </div>
                  <!-- <div class="product-more-option">
                  <div class="product-more-option-item">
                    <h6>Share On :</h6>
                    <ul class="social-profile style3 list-style">
                      <li>
                        <a target="_blank" href="https://facebook.com/"
                          ><i class="flaticon-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://twitter.com/">
                          <i class="flaticon-twitter-1"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://instagram.com/">
                          <i class="flaticon-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://youtube.com/">
                          <i class="flaticon-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import { getSingleItem, getRestaurantInfo } from "@/store/api";
import { baseUrl, addCart, getCart, getLocalStorage } from "@/store/service";
// import { getLocalStorage } from '../../store/service';
export default {
  props: [ "resInfo"],
  name: "shopdetails",
  data() {
    return {
      storeInfo: "",
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      sectionshape1: require("@/assets/img/secion-shape-1.png"),
      sectionshape2: require("@/assets/img/secion-shape-2.png"),
      singleproduct1: require("@/assets/img/feature/single-product-1.png"),
      singleproduct2: require("@/assets/img/feature/single-product-2.png"),
      singleproduct3: require("@/assets/img/feature/single-product-3.png"),
      singleproduct4: require("@/assets/img/feature/single-product-4.png"),
      featureitem1: require("@/assets/img/feature/feature-item-1.jpg"),
      circle1: require("@/assets/img/feature/circle-1.png"),
      featureitem2: require("@/assets/img/feature/feature-item-2.jpg"),
      featureitem3: require("@/assets/img/feature/feature-item-3.jpg"),
      featureitem4: require("@/assets/img/feature/feature-item-4.jpg"),
      featureitem5: require("@/assets/img/feature/feature-item-5.jpg"),
      featureitem6: require("@/assets/img/feature/feature-item-6.jpg"),
      form: {
        id: 0,
      },
      item: {},
      selectItem: "",
      options: [],
      cart: [""],
      close: "",
      single: [],
      multi: [],
      addons: [],
      selectedaddons: [],
      addOnTotal: 0,
      singleAddOnTotal: 0,
      multiAddOnTotal: 0,
      newCart: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.user = getLocalStorage("userData");
    this.getResInfo();
    this.getSingleItem();
    console.log(this.$route.params);
  },
  methods: {
    getResInfo() {

      getRestaurantInfo().then((res) => {
        console.log("res=====>",res);
        this.storeInfo = res.data;
        if (!this.user) {
          if (this.storeInfo.open === 1) {
            this.showButton = true;
          } else {
            this.showButton = false;
          }
        } else {
          if (this.user && !this.user.role) {
            console.log("user");
            if (this.storeInfo.open === 1) {
              this.showButton = true;
            } else {
              this.showButton = false;
            }
          } else {
            if (this.storeInfo.table_order_open === 1) {
              this.showButton = true;
            } else {
              this.showButton = false;
            }
          }
          console.log(this.showButton);
          if (this.showButton === false) {
            this.$toast.error("Restaurant is now closed.", {
              timeout: 1500,
            });
            this.$router.push("/shop");
          }
        }
      });
    },
    baseUrl() {
      return baseUrl();
    },
    getSingleItem() {
      this.form.id = this.$route.params.id;
      getSingleItem(this.form).then((res) => {
        this.item = res.data;
        if (this.item.addon_categories.length > 0) {
          this.options = [];
          this.addons = [];
          this.item.addon_categories.map((data) => {
            if (data.type === "SINGLE") {
              this.options.push(data);
            } else {
              this.addons.push(data);
            }
          });
        }
      });
    },
    selectAddon(event, category) {
      // console.log(event)
      var addon = JSON.parse(event.target.value);
      var select = {
        addon_category_name: category.name,
        addon_id: addon.id,
        addon_name: addon.name,
        price: addon.price,
      };
      console.log(select);
      if (category.type === "SINGLE") {
        this.single = [select];
        this.singleAddOnTotal = parseFloat(this.single[0].price);
        this.addOnTotal = this.singleAddOnTotal + this.multiAddOnTotal;
      } else if (category.type === "MULTI") {
        if (this.multi.length > 0) {
          for (var j = 0; j < this.multi.length; j++) {
            if (select.addon_id === this.multi[j].addon_id) {
              this.multiAddOnTotal -= parseFloat(this.multi[j].price);
              // console.log(this.addOnTotal + 'if')
              this.multi.splice(j, 1);
              this.addOnTotal = this.singleAddOnTotal + this.multiAddOnTotal;
              break;
            } else if (
              select.addon_id !== this.multi[j].addon_id &&
              j === this.multi.length - 1
            ) {
              this.multi.push(select);
              this.multiAddOnTotal += parseFloat(select.price);
              // console.log(this.addOnTotal + 'else-if')
              this.addOnTotal = this.singleAddOnTotal + this.multiAddOnTotal;
              break;
            }
          }
        } else {
          this.multi = [select];
          this.multiAddOnTotal += parseFloat(this.multi[0].price);
          this.addOnTotal = this.singleAddOnTotal + this.multiAddOnTotal;
          // console.log(this.addOnTotal + 'else')
        }
      }
    },
    addtocart(item) {
      item.selectedaddons = this.single.concat(this.multi);
      item.quantity = 1;
      item.addOnTotal = this.addOnTotal;
      if (this.cart != null) {
        this.cart = getCart("cart");
        this.cart.push(item);
      } else {
        this.cart = [item];
      }
      console.log(this.cart);
      // this.cart = item
      this.$toast.success("An item added to cart.", {
        timeout: 1000,
      });
      this.newCart = this.cart;
      // this.$emit("addItem", this.cart);
      addCart("cart", JSON.stringify(this.cart));
      this.close = "close";
    },
  },
};
</script>
<style scoped>
#selectoptions {
  width: 100%;
  height: 45px;
  background-color: #eeeff2;
  padding: 10px 15px;
  border: none;
  color: #545454;
  border-radius: 5px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(/assets/img/offer/down-arrow.png);
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85% 50%;
}
.checkbox.style2 label {
  margin-right: 24px;
}
.accordion-item .accordion-header .accordion-button {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  border-radius: 0;
  border: none;
  color: #fff;
  text-align: left;
  background: #f41f31;
  padding: 6px 30px 4px 20px;
  border-radius: 15px;
}
select.form-select.rounded {
  border-radius: 43px !important;
}
.feature-slider.swiper-container {
  width: 100%;
  max-width: 366px;
  float: left;
}
</style>
